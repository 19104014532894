<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-8 offset-sm-2">
        <div>
          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <label for="Username">Username</label>
              <input
                type="text"
                v-model="user.username"
                id="username"
                name="username"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.user.username.$error }"
              />
              <div
                v-if="submitted && !$v.user.username.required"
                class="invalid-feedback"
              >
                Username is required
              </div>
            </div>
            <div class="form-group">
              <label for="lastName">Phone number</label>
              <input
                type="text"
                v-model="user.phone"
                id="phone"
                name="phone"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.user.phone.$error }"
              />
              <div
                v-if="submitted && !$v.user.phone.required"
                class="invalid-feedback"
              >
                Phone number is required
              </div>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                v-model="user.email"
                id="email"
                name="email"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.user.email.$error }"
              />
              <div
                v-if="submitted && $v.user.email.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.user.email.required">Email is required</span>
                <span v-if="!$v.user.email.email">Email is invalid</span>
              </div>
            </div>

            <CSelect
              label="Role"
              v-model="user.role"
              :options="['admin', 'support']"
            />

            <div class="form-group">
              <button class="btn btn-primary">Add User</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <CToaster :autohide="3000" v-if="this.toast.show">
      <CToast :show="true" :header="toast.title">
        {{ toast.msg }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import UserServiceApi from "@/services/api/users";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "app",
  data() {
    return {
      selectedUser: this.$store.state.user.selectedUser,
      user: {
        username: "",
        phone: "",
        email: "",
        role: "admin",
      },
      toast: {
        show: false,
        title: "",
        msg: "",
      },
      submitted: false,
    };
  },
  validations: {
    user: {
      username: { required },
      phone: { required },
      email: { required, email },
      role: { required },
    },
  },
  mounted() {
    this.user = { ...this.selectedUser };
  },
  methods: {
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.createUser(this.user);
    },
    createUser(data) {
      UserServiceApi.registerAdmin(data)
        .then((res) => {
          console.log(res);
          this.toast.show = true;
          (this.toast.title = "Admin registration"),
            (this.toast.msg = "Admin registered successfully!");
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
  },
};
</script>